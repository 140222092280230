// eslint-disable-next-line
export default {
  Palette: {
    height: "97vh",
    display: "flex",
    flexDirection: "column",
  },
  PaletteColors: {
    height: "90%",
  },
};
