const styles = {
    picker: {
       width: "20rem !important",
       marginTop: "2rem"
    },
    addColor: {
        width: "100%",
        fontSize: "2rem",
        fontWeight: 600,
        fontFamily: "cursive" 
    },
    colorNameInput: {
        width: "100%",
        height: "70px",
        
    }
}

export default styles;