// eslint-disable-next-line
export default {
  PaletteFooter: {
    backgroundColor: "rgba(14, 248, 248, 0.034)",
    height: "5vh",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontWeight: "bold",
    marginRight: "0.8rem",
  },
  emoji: {
    fontSize: "1.3rem",
  },
};
